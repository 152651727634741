<template>
    <el-table v-loading="loading" class="bank-files-sub-container" :row-class-name="tableRowClassName" :data="children" size="mini" :header-cell-style="{ background: '#e5e5e5' }">
        <!-- :row-style="{ background: '#f5f5f5' }" -->
        <!-- <el-table-column prop="matchSum" label="Match" width="80" /> -->
        <el-table-column type="expand" width="25">
            <template slot-scope="props">
                <BankFilesSubForm :id="props.row.clientFundsAccountId" :sub-id="props.row.id" @openRefundModal="openRefundModal" />
            </template>
        </el-table-column>
        <el-table-column prop="" width="100" align="right">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0" type="danger" effect="dark" style="width: 80px">Omatchad</el-tag>
                <el-tag v-if="scope.row.status == 1" color="#acbff1" type="" style="width: 80px">Matchad</el-tag>
                <el-tag v-if="scope.row.status == 2" type="warning" style="width: 80px">Skickad</el-tag>
            </template>
        </el-table-column>

        <el-table-column prop="date" label="Datum" sortable="custom" width="150">
            <template slot-scope="scope">
                <p>{{ formatDate(scope.row.date) }}</p>
            </template>
        </el-table-column>
        <el-table-column prop="textUnstruct" label="Text">
            <template slot-scope="scope">
                <p>{{ getText(scope.row) }}</p>
            </template>
        </el-table-column>
        <el-table-column label="Typ" width="140" />
        <el-table-column prop="originalCurrency" label="Valuta" width="80" />
        <el-table-column prop="amount" label="Belopp" width="140" align="right">
            <template slot-scope="scope"> {{ scope.row.amount | swedishNumberFormat }}</template>
        </el-table-column>
        <el-table-column prop="matchBalance" label="Restbelopp" width="140" align="right">
            <template slot-scope="scope"> {{ scope.row.matchBalance | swedishNumberFormat }}</template>
        </el-table-column>
        <el-table-column prop="clientNumber" label="Klient nr" width="80" />
        <el-table-column prop="clientName" label="Klient" width="300" show-overflow-tooltip />
        <el-table-column prop="" width="100" align="right" />
    </el-table>
</template>

<script>
import moment from "moment";
import Api from "./../bank_files.api";

export default {
    components: {
        BankFilesSubForm: () => import(/* webpackChunkName: "BankFilesSubForm" */ "./BankFilesSubForm"),
    },
    props: {
        row: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            children: [],
            loading: false,
        };
    },
    async created() {
        await this.expandChange(this.row);
    },
    methods: {
        async expandChange(row) {
            this.loading = true;
            this.children = await Api.getChildren(row.id);
            this.loading = false;
        },

        getText(row) {
            if (row.text.startsWith("LBP") || row.text.startsWith("LBS") || row.text.startsWith("LBE")) {
                return row.receiverName;
            } else if (row.type == 5) {
                return row.receiverName;
            } else return row.textUnstruct || row.message;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        headerRowClassName({ row, rowIndex }) {
            console.log("🚀 - file: BankFilesSubTable.vue:44 - headerRowClassName - row:", row);
            // You can add logic here to determine the class name for the header row.
            if (rowIndex === 0) {
                return "header-row-highlight";
            } else {
                return "header-row-default";
            }
        },
        openRefundModal(ids) {
            console.log("🚀 - file: BankFilesSubTable.vue:91 - openRefundModal - id:", ids);
            this.$emit("openRefundModal", ids);
        },
        tableRowClassName() {
            console.log("🚀 - file: BankFilesSubTable.vue:97 - tableRowClassName - tableRowClassName:");
            return "header-row-default";
        },
    },
};
</script>
<style>
/* .el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
.el-table .red-row {
    background: #f7acac;
} */
.header-row-default {
    background-color: #f0f0f0 !important;
}
.header-row-highlight {
    background-color: #3498db !important;
    color: white;
}
/* .el-table tr {
    background-color: #8d5353 !important;
} */
</style>
